<template>
  <div class="special" :class="[hotClassId?'special'+hotClassId:'']">
     <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="finishedText"
      @load="moreOnLoad"
    >
    <div class="titleBox">
      <img class="titleIcon" :src="hotType[hotClassId].titleIcon"/>
    </div>
    <product-list :class="[isPlaceholder?'':'productListBox']" :productList="productList" :isPlaceholder="false"/>
      <placeholder v-if="isPlaceholder"></placeholder>
    </van-list>
  </div>
</template>
<script>
import ProductList from '@components/common/ProductList'
import Vue from 'vue'
import { List } from 'vant'
import Placeholder from "@/components/common/Placeholder";
Vue.use(List)
export default {
  name: 'Special',
  components: {
    ProductList,
    Placeholder
  },
  data () {
    return {
      hotClassId: 'SP1',
      pageIndex: 1,
      productList: [],
      isPlaceholder: false,
      finished: false,
      loading: true,
      isfirst: true,
      hotType: {
        SP1: {
          bg: require('@/assets/img/home/hot/img_red_2@2x.png'),
          titleIcon: require('@/assets/img/home/hot/img_red_1@2x.png')
        },
        SP2: {
          bg: require('@/assets/img/home/hot/img_blue_2@2x.png'),
          titleIcon: require('@/assets/img/home/hot/img_blue_1@2x.png')
        },
        SP3: {
          bg: require('@/assets/img/home/hot/img_green_2@2x.png'),
          titleIcon: require('@/assets/img/home/hot/img_green_1@2x.png')
        }
      }
    }
  },
  computed: {
    finishedText () {
      if (this.isPlaceholder) {
        return ''
      } else {
        return this.$t.noMoreData
      }
    }
  },
  created () {
    let { hotClassId } = this.$route.query;
    (hotClassId) && (this.hotClassId = hotClassId)
  },
  mounted () {
    this.loadSpecialProduct()
  },
  methods: {
    loadSpecialProduct () {
      this.$commonMethod.showLoading()
      let param = {
        HotClassId: this.hotClassId,
        PageIndex: this.pageIndex
      }
      this.$api.index
        .loadSpecialProduct(param)
        .then(res => {
          this.$commonMethod.hideLoading()
          this.loading = false
          console.log(res)
          if (res.data == null || res.data === '' || res.data.length === 0) {
            this.isPlaceholder = true
          } else {
            this.productList = res.data
            this.isPlaceholder = false
          }
          this.$commonMethod.hideLoading()
        })
        .catch(error => {
          // this.isfirst = false
          // console.log(error);
          this.$commonMethod.hideLoading()
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status
          })
        })
      // console.log("这是isfirst" + this.isfirst);
    },
    moreOnLoad () {
      // console.log('加载更多')
      console.log('执行加载更多')
      let param = {
        PageIndex: this.pageIndex + 1,
        HotClassId: this.hotClassId
      }
      this.loading = true
      this.$api.index
        .loadSpecialProduct(param)
        .then(res => {
          console.log(res)
          const data = res.data
          if (data == null || data === '' || data.length === 0) {
            this.loading = false
            this.finished = true
            // console.log('无数据')
          } else {
            this.productList = this.productList.concat(data)
            this.pageIndex = this.pageIndex + 1
            this.loading = false
            this.finished = false
            // console.log('有数据')
          }
        })
        .catch(error => {
          // console.log(error);
          this.$commonMethod.hideLoading()
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status
          })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.specialSP1{
 background-image: url("~assets/img/home/hot/img_red_2@2x.png");
 background-size: 100%;
}
.specialSP2{
 background-image: url("~assets/img/home/hot/img_blue_2@2x.png");
 background-size: 100%;
}
.specialSP3{
 background-image: url("~assets/img/home/hot/img_green_2@2x.png");
 background-size: 100%;
}
.special{
  width: 100%;
  position: relative;
  // background-image: url("~assets/img/home/hot/img_blue_2@2x.png");
  // background-size: 100%;
  min-height: 100vh;
  padding-bottom: 20px;
  // padding-bottom: 150px;
  ::v-deep .ProductList{
    background-color: transparent;
    .productBox{
      background-color: transparent;
    }
  }
  ::v-deep .van-list__finished-text{
    color: #ffffff;
  }
  .titleBox{
    width: 100%;
    .titleIcon{
      width: 100%;
      object-fit: cover;
    }
  }
  .productListBox{
    margin-top: -22%;
  }
  // background-size: cover;
}
</style>
